<template>
  <div class="page have-last-year-page" v-loading="loading">
    <div class="right" v-if="info">
      <HandleBtn :data="{}" v-if="false"></HandleBtn>
      <Title :title="currentYear + '年采购'">
        <div class="h-t-s">
          <div class="jxz" v-if="!info.isEnd">进行中</div>
          <div v-else></div>
          <HandleBtn theme="orange" :dataList="batchData"></HandleBtn>
        </div>
      </Title>
      <div class="top">
        <div class="t-1">
          <Status :status-list="statusList" :data="showDetail"></Status>
        </div>
        <div class="t-2">
          <StatusPie :status-list="statusList" :data="showDetail"></StatusPie>
        </div>
      </div>
      <div class="center">
        <div class="c-1">
          <Total :data="showDetail" theme="orange"
          :actions="[{key: 'detail', label: '详细'}]"
          @clickBtn="goDetail(currentYear)"
          ></Total>
        </div>
        <div class="c-2">
          <BusOrg
            @chooseData="updateSearchParm"
            theme="orange" :data="detail"></BusOrg>
        </div>
      </div>
      <div class="bottom">
        <SubmitOrg theme="orange"
          @chooseData="updateSearchParm"
          :data="detail"></SubmitOrg>
      </div>
    </div>
    <div class="right" v-else>
      <Title :title="currentYear + '年采购'"></Title>
      <div class="no-img">
        <img src="/static/images/budget/wait.png" alt="">
        <div>暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import { purchaseBatchRequest, purchaseBatchDetailsRequest, statusManageRequest } from '../../api'

import {
  getBatchInfo,
  detailFilter
} from './lib'

import Title from './components/cmp/title'
import Status from './components/batch_detail/status'
import StatusPie from './components/batch_detail/statusPie'
import SubmitOrg from './components/batch_detail/submitOrg'
import BusOrg from './components/batch_detail/busOrg'
import Total from './components/batch_detail/total'
import HandleBtn from './components/batch_detail/handleBtn'

export default {
  components: {
    Title,
    Status,
    StatusPie,
    SubmitOrg,
    BusOrg,
    Total,
    HandleBtn
  },
  data () {
    return {
      loading: false,
      currentYear: null,
      info: null,
      detail: [],
      batchData: [],
      count: {},
      searchParm: null,
      statusList: []
    }
  },
  created () {
    this.loadConfig()
    this.currentYear = new Date().getFullYear()
    this.loadData()
  },
  computed: {
    showDetail () {
      return this.detail.filter(v => detailFilter(v, this.searchParm))
    },
    currentShowDetail () {
      return this.current.detail.filter(v => detailFilter(v, this.current.searchParm))
    },
    budgetYearTypeList () {
      return this.$store.getters.budgetYearTypeList
    },
    lastTotal () {
      return {
        applyTotal: this.lastCount.applyTotal || 0,
        normalApplyTotal: this.lastCount.normal.applyTotal || 0,
        appendApplyTotal: this.lastCount.append.applyTotal || 0,
        preTotal: this.last.info.preTotal
      }
    }
  },
  methods: {
    updateSearchParm (parm) {
      if (parm && this.searchParm && parm.key === this.searchParm.key) {
        this.searchParm = null
      } else {
        this.searchParm = parm
      }
    },
    goDetail (year) {
      this.$router.push({
        name: 'internalcontrol.purchase.batch_detail',
        query: {
          year
        }
      })
    },
    async loadConfig () {
      let data = await statusManageRequest.config({
        statusGroupKey: 'purchaseBatchDetail'
      })
      this.statusList = data.statusList
    },
    async loadData () {
      this.loading = true
      let data = []
      let parm = {year: this.currentYear}
      if (this.$authFunsProxy.get) {
        data = await purchaseBatchRequest.getInfo(parm)
      } else if (this.$authFunsProxy.getByAuth) {
        data = await purchaseBatchRequest.getMyInfo(parm)
      }
      data.forEach(item => {
        item.label = item.year + ' ' + item.batch
      })
      this.batchData = data
      this.info = getBatchInfo(data)
      if (this.info) {
        this.detail = []
        if (this.$authFunsProxy.get) {
          this.detail = await purchaseBatchDetailsRequest.getInfo(parm)
        } else if (this.$authFunsProxy.getByAuth) {
          this.detail = await purchaseBatchDetailsRequest.getInfoByAuth(parm)
        }
      }
      this.loading = false
    }
  }
}
</script>

<style scoped lang="less">
.page {
  display: flex;
  flex: 1;
  background: #EEF3FA;
  .left {
    flex: 1255;
    height: 100%;
    padding: 0 16px 12px;
    display: flex;
    flex-direction: column;
  }
  .right {
    flex: 505;
    height: 100%;
    padding: 0 16px 12px;
    display: flex;
    flex-direction: column;
  }
  .no-img {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    img {
      width: 243px;
    }
    div {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #748295;
      margin-top: 23px;
    }
  }
  .top {
    flex: 295;
    margin-top: 14px;
    display: flex;
    margin-bottom: 13px;
    .t-1 {
      height: 100%;
      margin-right: 12px;
    }
    .t-2 {
      height: 100%;
    }
  }
  .center {
    flex: 312;
    display: flex;
    margin-bottom: 13px;
    .c-1 {
      height: 100%;
      margin-right: 12px;
    }
    .c-2 {
      height: 100%;
    }
  }
  .bottom {
    flex: 329;
  }
}
.no-last-year-page {
  .left {
    background: #F7F9FD;
    box-shadow: 0px 2px 21px 0px rgba(100,131,175,0.4);
  }
  .t-1 {
    flex: 386;
  }
  .t-2 {
    flex: 824;
  }
  .c-1 {
    flex: 798;
  }
  .c-2 {
    flex: 413;
  }
}

.have-last-year-page {
  .left {
    flex: 479;
  }
  .right {
    flex: 1249;
    background: #F7F9FD;
    box-shadow: 0px 2px 21px 0px rgba(100,131,175,0.4);
  }
  .t-1 {
    flex: 824;
  }
  .t-2 {
    flex: 384;
  }
  .c-1 {
    flex: 411;
  }
  .c-2 {
    flex: 798;
  }
}
.jxz {
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 21px;
  padding: 0 5px;
  background: #FF5050;
  border-radius: 3px;
  margin-left: 12px;
}
.h-t-s {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: flex-end;
  }
  .budget-btn {
    background: #3776ED;
    border-radius: 4px;
    height: 26px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    padding: 0 10px;
    display: flex;
    align-items: center;
    img {
      height: 13px;
      padding-right: 5px;
    }
  }
</style>