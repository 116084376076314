<template>
  <Block theme="orange" title="采购申请审批情况">
    <chart-pie center-y="60%" :legend-bottom="'0'" :colors="['#FF9D6B', '#FF72B1', '#FF544C', '#EFF44D', '#FFC328']" class="chart" :dataList="showData"></chart-pie>
  </Block>
</template>

<script>
import ChartPie from '../cmp/pie'
import Block from '../cmp/block'

export default {
  components: {
    Block,
    ChartPie
  },
  props: {
    statusList: {
      type: Array
    },
    data: {
      type: Array
    },
    theme: {
      type: String
    }
  },
  methods: {
    statusFilter (status, statusKey) {
      return (status === 'draw_2' && statusKey === 'draw') || status === statusKey
    }
  },
  computed: {
    showData () {
      return this.statusList.filter(v => v.statusKey !== 'draw_2').map(v => {
        return {
          name: v.label,
          value: this.data.filter(d => this.statusFilter(d.status, v.statusKey)).reduce((c, item) => {
            return c + item.total
          }, 0)
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.chart {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
